import React, { useEffect, useMemo, useState } from 'react';
import { M12HeroModule } from '~/lib/data-contract';
import { useCookieConsent } from '~/shared/hooks/useCookieConsent';
import { useModule } from '~/templates';
import { ModuleContainer } from '../ModuleContainer';
import { SpotTextBlock } from '../TextBlock';
import { queries, useThemeShade } from '~/theme';
import { usePage } from '$templates/pages';
import { M10HeroImage } from '$templates/blocks/components/M10Hero/M10HeroImage';
import { M10HeroVideo } from '$templates/blocks/components/M10Hero/M10HeroVideo';
import {
    StyledM10,
    StyledM10AlignContent,
    StyledM10Content,
    StyledM10TextContentWrapper,
    StyledMediaWrapper,
    StyledParallaxWrapper,
} from '$templates/blocks/components/M10Hero/styled';
import {
    parallaxInputRange,
    parallaxOutputRangeMedia,
    parallaxOutputRangeTextBlock,
} from '$templates/blocks/components/M10Hero/M10HeroSettings';

export type M12HeroProps = M12HeroModule;

export const M12Hero = ({
    headline,
    subHeadline,
    subHeadlineUppercase,
    text,
    categories,
    height: heightTemp,
    mediaLayout: mediaLayoutTemp,
    horizontalAlignment = 'left',
    verticalAlignment = 'center',
    spacingTop,
    backgroundColor: initialBackgroundColor,
    hideMobile,
    hideDesktop,
    ...rest
}: M12HeroProps) => {
    const pagesPossibleToHideModule = [
        'p20FrontPage',
        'p40productListPage',
        'p41productListPage',
        'p60ModulePage',
    ];
    const { type } = usePage();
    const mediaLayout = mediaLayoutTemp || 'full';
    const height = heightTemp || 'normal';
    const { index } = useModule();
    const { backgroundColor, backgroundShade, skeletonShade } = useThemeShade({
        backgroundColor: initialBackgroundColor,
    });
    const { marketing, statistic } = useCookieConsent();

    const firstItem = useMemo(() => (categories && categories.length ? categories[0] : undefined), [
        categories,
    ]);

    // Because the consent placeholder covers hero text if the layout is full, we fallback to showing image
    const [mediaType, setMediaType] = useState<'video' | 'image'>(
        firstItem?.video?.src && ((marketing && statistic) || mediaLayout !== 'full')
            ? 'video'
            : 'image'
    );

    const isFullWidth = mediaLayout === 'full';
    const shouldLinkCover = Boolean(isFullWidth && firstItem?.callToAction?.url);
    const hasMediaGradient =
        (firstItem?.hasMediaFilter || false) &&
        isFullWidth &&
        Boolean(headline || subHeadline || text || firstItem?.callToAction);

    useEffect(() => {
        setMediaType(firstItem?.video?.src && marketing && statistic ? 'video' : 'image');
    }, [marketing, statistic, firstItem?.video, mediaLayout, setMediaType]);

    // Reduce image size if above the fold
    const imageSizesM10 = {
        full: index < 2 ? '80vw' : '100vw',
        split: `${queries.md} 67vw, ${index < 2 ? '80vw' : '100vw'}`,
    };
    const imageSizes = imageSizesM10[mediaLayout];

    // If verticalAlignment is 'bottom', dont parallax. Avoids possibly going out of bounds.
    const shouldDisableParallaxOnText = verticalAlignment === 'bottom';

    return (
        <ModuleContainer
            {...rest}
            spacingTop={index === 0 ? 'none' : spacingTop}
            hideMobile={hideMobile && type && pagesPossibleToHideModule.includes(type)}
            hideDesktop={hideDesktop && type && pagesPossibleToHideModule.includes(type)}
        >
            <StyledM10
                height={height}
                mediaLayout={mediaLayout}
                backgroundColor={backgroundColor}
                backgroundShade={backgroundShade}
                index={index}
            >
                <StyledM10Content
                    mediaLayout={mediaLayout}
                    contentAlignment={horizontalAlignment}
                    mediaAlignment={firstItem?.mediaHorizontalAlignment || 'left'}
                    height={height}
                    title={shouldLinkCover ? firstItem?.callToAction?.title : undefined}
                >
                    <StyledM10TextContentWrapper>
                        <StyledParallaxWrapper
                            disabled={shouldDisableParallaxOnText || mediaLayout === 'split'}
                            offset={['start start', 'end start']}
                            type="instant"
                            inputRange={parallaxInputRange}
                            outputRange={parallaxOutputRangeTextBlock}
                        >
                            <StyledM10AlignContent
                                alignment={horizontalAlignment}
                                position={verticalAlignment}
                            >
                                <SpotTextBlock
                                    headline={headline}
                                    headlineVariant={index === 0 ? 'display1' : 'display2'}
                                    text={text}
                                    subheadline={subHeadline}
                                    subHeadlineUppercase={subHeadlineUppercase}
                                    callToAction={firstItem?.callToAction}
                                    backgroundColor={backgroundColor}
                                    useTranslationForLinkTitle
                                />
                            </StyledM10AlignContent>
                        </StyledParallaxWrapper>
                    </StyledM10TextContentWrapper>
                    {(firstItem?.image || firstItem?.video) && (
                        <StyledMediaWrapper
                            hasGradient={hasMediaGradient}
                            horizontalAlignment={horizontalAlignment}
                            backgroundColor={backgroundColor}
                        >
                            <StyledParallaxWrapper
                                offset={['start start', 'end start']}
                                type="instant"
                                inputRange={parallaxInputRange}
                                outputRange={parallaxOutputRangeMedia}
                            >
                                {mediaType === 'image' && (
                                    <M10HeroImage
                                        {...{
                                            mediaType,
                                            image: firstItem?.image,
                                            isFullWidth,
                                            index,
                                            mediaLayout,
                                            height,
                                            skeletonShade,
                                            callToAction: firstItem?.callToAction,
                                            sizes: imageSizes,
                                        }}
                                    />
                                )}
                                {mediaType === 'video' && (
                                    <M10HeroVideo
                                        {...{
                                            video: firstItem?.video,
                                            isFullWidth,
                                            callToAction: firstItem?.callToAction,
                                            imageSrc: firstItem?.image?.src,
                                            posterSizes: imageSizes,
                                        }}
                                    />
                                )}
                            </StyledParallaxWrapper>
                        </StyledMediaWrapper>
                    )}
                </StyledM10Content>
            </StyledM10>
        </ModuleContainer>
    );
};

export default M12Hero;
